import {INIT_APP,
    BIND_BUTTONS,
    BIND_MAIN_BTN,
    BIND_BACK_BTN,
    SET_SELECT_FLAT,
    SAVE_END_INDEX, 
    SAVE_SCROLL_TOP, 
    GET_OPERATOR, 
    GET_OPERATOR_FAILURE,
    SET_WEBAPP_PLATFORM} from '../actions/appActions';

const initialState = {
    endIndex: 12,
    scrollTop: 0,
    operator: null,
    error: null,
    selectFlat: -1,
    webAppPlatform: null,
  };

const appReducer = (state = initialState, action) => {
switch (action.type) {
    case INIT_APP:
        return{
            ...state,
        }
    case BIND_BUTTONS:
        return{
            ...state,
        }
    case BIND_MAIN_BTN:
        return{
            ...state,
        }
    case BIND_BACK_BTN:
        return{
            ...state,
        }
    case SAVE_END_INDEX:
        return {
            ...state,
            endIndex: action.payload
        };
    case SAVE_SCROLL_TOP:
        return {
            ...state,
            scrollTop: action.payload
        };
    case GET_OPERATOR:
        return {
            ...state,
            operator: action.payload
        }
    case GET_OPERATOR_FAILURE:
        return {
            ...state,
            error: action.payload,
        };
    case SET_SELECT_FLAT:
        return {
            ...state,
            selectFlat: action.payload,
        }
    case SET_WEBAPP_PLATFORM:
        return {
            ...state,
            webAppPlatform: action.payload,
        }
    default:
    return state;
}
};

export default appReducer;