import React, { useEffect, useState } from 'react';
import classes from "./Filter.module.css";
import { useDispatch, useSelector } from 'react-redux';
import { setRentType, setRoomsCount, setLocations, fetchLocations, fetchRentTypes, setRentPeriodType, fetchRentPeriodTypes, fetchRoomsCount, setConveniences, fetchConveniences } from '../../store/actions/filterActions';
import Select from "./select/Select";
import {text} from "../../translate";
import {useTelegram} from "../../hooks/useTelegram";
import Range from "./range/Range";
import { applyFilters, applyRentTypeFilter, applyRoomsFilter, applyLocationFilter, applyPriceFilter } from '../../store/actions/catalogActions';

const Filter = () => {
  const dispatch = useDispatch();  
  const {lang} = useTelegram();
  const { rentType, roomsCount, locations, priceRange, rentPeriodTypes, conveniences,
      selectedRentType, selectedRoomsCount, selectedLocation, selectedRentPeriodType, selectedConveniences } = useSelector(state => state.filter);

  const {filteredCards: cards, locations: allLocations} = useSelector(state => state.catalog);

  useEffect(() => {
    dispatch(fetchRentTypes([
      { id: 1, name: text[lang].filter.apartment },
      { id: 0, name: text[lang].filter.flat }
    ]));
    dispatch(fetchRentPeriodTypes(
      [
        //{id: 0, name: text[lang].filter.rent_daily},
        {id: 1, name: text[lang].filter.rent_monthly},
        {id: 2, name: text[lang].filter.rent_yearly},
      ]
    ));
    dispatch(fetchRoomsCount(
      [
        {id:1, name: text[lang].filter.rooms.room, ru_id: text['ru'].rooms.room},
        {id:2, name: text[lang].filter.rooms.bunk, ru_id: text['ru'].rooms.bunk},
        {id:3, name: text[lang].filter.rooms.studio, ru_id: text['ru'].rooms.studio},
        {id:4, name: text[lang].filter.rooms.one_bedroom, ru_id: text['ru'].rooms.one_bedroom},
        {id:5, name: text[lang].filter.rooms.two_bedroom, ru_id: text['ru'].rooms.two_bedroom},
        {id:6, name: text[lang].filter.rooms.three_bedroom, ru_id: text['ru'].rooms.three_bedroom},
        {id:7, name: text[lang].filter.rooms.village, ru_id: text['ru'].rooms.village},
    ]
    ));
    dispatch(fetchConveniences([
      { id: 'is_near_subway', name: text[lang].flat.icons.is_near_subway },
      { id: 'is_balcony', name: text[lang].flat.icons.is_balcony },
      { id: 'is_gym', name: text[lang].flat.icons.is_gym },
      { id: 'is_pool', name: text[lang].flat.icons.is_pool },
      /*{ id: 'is_pets', name: text[lang].flat.icons.is_pets },
      { id: 'is_wifi', name: text[lang].flat.icons.is_wifi },
      { id: 'is_car_charge', name: text[lang].flat.icons.is_car_charge },
      { id: 'is_conditioner', name: text[lang].flat.icons.is_conditioner },
      { id: 'is_restaurant', name: text[lang].flat.icons.is_restaurant },
      { id: 'is_tv', name: text[lang].flat.icons.is_tv },
      { id: 'is_kitchenette', name: text[lang].flat.icons.is_kitchenette },
      { id: 'is_kitchen_utensils', name: text[lang].flat.icons.is_kitchen_utensils }, 
      { id: 'is_parking', name: text[lang].flat.icons.is_parking },
      { id: 'is_bathroom', name: text[lang].flat.icons.is_bathroom },
      { id: 'is_jacuzzi', name: text[lang].flat.icons.is_jacuzzi },
      { id: 'is_family_apartment', name: text[lang].flat.icons.is_family_apartment },
      { id: 'is_safe', name: text[lang].flat.icons.is_safe },
      { id: 'is_hair_dryer', name: text[lang].flat.icons.is_hair_dryer },
      { id: 'is_iron_for_clothes', name: text[lang].flat.icons.is_iron_for_clothes },
      { id: 'is_kettle', name: text[lang].flat.icons.is_kettle },
      { id: 'is_fridge', name: text[lang].flat.icons.is_fridge },
      { id: 'is_microwave', name: text[lang].flat.icons.is_microwave },
      { id: 'is_daily_cleaning', name: text[lang].flat.icons.is_daily_cleaning },
      { id: 'is_desktop', name: text[lang].flat.icons.is_desktop },*/
    ]
    ));
  }, [dispatch]);

  useEffect(() => {
    if (allLocations.length > 0) {
      dispatch(fetchLocations(allLocations));
    }
  }, [dispatch, allLocations]);

  const handleRentTypeChange = (selectedRentType) => {
    dispatch(setRentType(selectedRentType));
  };

  const handleRoomsCountChange = (selectedRoomsCount) => {
    dispatch(setRoomsCount(selectedRoomsCount));
  };

  const handleLocationChange = (selectedLocation) => {
    dispatch(setLocations(selectedLocation));
  };
  const handleRentPeriodTypeChange = (selectedRentPeriodType) => {
    dispatch(setRentPeriodType(selectedRentPeriodType));
  };
  const handleConveniencesChange = (selectedConveniences) => {
    dispatch(setConveniences(selectedConveniences));
  }

  useEffect(() => {
    // Вызываем applyFilters только если хотя бы один параметр изменен
    dispatch(applyFilters(selectedRentType, selectedRoomsCount, selectedLocation, priceRange, selectedRentPeriodType, selectedConveniences));
  }, [dispatch, selectedRentType, selectedRoomsCount, selectedLocation, priceRange, selectedRentPeriodType, selectedConveniences]);

  /*useEffect(() => {
    // Вызываем applyRentTypeFilter при изменении типа жилья
    dispatch(applyRentTypeFilter(selectedRentType));
  }, [dispatch, selectedRentType]);
  useEffect(() => {
    // Вызываем applyRoomsFilter при изменении кол-ва комнат
    dispatch(applyRoomsFilter(selectedRoomsCount));
  }, [dispatch, selectedRoomsCount]);
  useEffect(() => {
    // Вызываем applyLocationFilter при изменении списка районов
    dispatch(applyLocationFilter(selectedLocation));
  }, [dispatch, selectedLocation]);
  useEffect(() => {
    // Вызываем applyPriceFilter при изменении диапазона цены
    dispatch(applyPriceFilter(priceRange));
  }, [dispatch, priceRange]);*/

  return (
     <div className={classes._}>
          {/*<Select 
            id={1}
            name={text[lang].filter.rent} 
            options={rentType || []}  
            onOptionChange={handleRentTypeChange} 
            multi={true}
  />*/}
          <Select 
            id={3}
            name={text[lang].filter.rent} 
            options={roomsCount || []} 
            onOptionChange={handleRoomsCountChange} 
            multi={true}
          />
         {/*<Select
            id={2}
            name={text[lang].filter.rent_period}
            options={rentPeriodTypes}
            onOptionChange={handleRentPeriodTypeChange}
            multi={false}
          />*/}
         
         <Select
            id={4} 
            name={text[lang].filter.location} 
            options={locations || []} 
            onOptionChange={handleLocationChange} 
            multi={true}
         />
         <Select
            id={5} 
            name={text[lang].filter.conveniences} 
            options={conveniences || []} 
            onOptionChange={handleConveniencesChange} 
            multi={true}
         />
         <Range/>
    </div>
  );
};

export default Filter;