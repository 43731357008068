import axios from 'axios';
import { fetchFlatRequest, fetchFlatSuccess, fetchFlatFailure } from '../actions/flatActions';
import {api_addr} from './urls';
import { useTelegram } from '../../hooks/useTelegram'; 


export const fetchFlat = (setItem, id) => async (dispatch) => {
  dispatch(fetchFlatRequest());
  const {webApp} = useTelegram();  
  const telegramId = webApp.initDataUnsafe?.user?.id.toString();
  const data = {
      apartment_id: id,
      client_telegram_id: telegramId
    };
  //Адрес для получения информации об объекте
  const url = api_addr + `/apartments/${id}`;
  localStorage.setItem('FlatId', id);
  setItem('cloudFlatId', id);
  const urlStatistics = api_addr + `/apartments/showings`;
  try {
    //Получение информации об объекте  
    const response = await axios.get(url);

    dispatch(fetchFlatSuccess(response.data));
    console.log(response.data);
    //Отправка статистики о просмотре объявления
    await axios.post(urlStatistics,data);

  } catch (error) {
    dispatch(fetchFlatFailure(error.message));
  }
};

export const fetchFlatTest = () => async (dispatch) => {
  dispatch(fetchFlatRequest());

  const data = {
    id: 1,
    location: {
        id: 1,
        name: "Dubai Marina"
    },
    apartment_type: 0,
    title: "Видовая квартира в ЖК “Парк Челюскинцев“",
    address: "г. Москва, ул. Ленина, д.3, к.4",
    price: 9265,
    room: {
        id: 1,
        value: 5
    },
    photos: [],
    likes_count: 0,
    clicks_count: 0,
    is_liked: false,
    total_area: 120,
    residential_area: 113,
    floor_number: 6,
    number_of_floors: 9,
    year_of_construction: 2024,
    description: "Успейте купить квартиру в ЖК «Парк Челюскинцев» в комфортном и уютном комплексе в центре Екатеринбурга, рядом с….",
    ceiling_height: 10,
    bathroom_type: "some bathroom type 3",
    is_balcony: true,
    is_new_building: true,
    is_renovated: true,
    is_furniture: true,
    is_fenced_area: true,
    is_car_parking: true,
    is_wifi: true,
    is_car_charge: true,
    is_pets: true,
    is_conditioner: true,
    is_restaurant: true,
    is_tv: true,
    is_kitchenette: true,
    is_kitchen_utensils: true,
    is_pool: true,
    is_parking: true,
    is_bathroom: true,
    is_jacuzzi: true,
    is_family_apartment: true,
    is_safe: true,
    is_hair_dryer: true,
    is_iron_for_clothes: true,
    is_kettle: true,
    is_fridge: true,
    is_microwave: true,
    is_daily_cleaning: true,
    is_gym: true,
    is_desktop: true,
    map_ref: null
}
  dispatch(fetchFlatSuccess(data));
} ; 



  