import { FETCH_FLAT_REQUEST,
        FETCH_FLAT_SUCCESS, 
        FETCH_FLAT_FAILURE,
        CLEAR_FLAT_STATE } from '../actions/flatActions';

const initialState = {
  flat: {},
  loading: false,
  error: null
};

const flatReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FLAT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_FLAT_SUCCESS:
      return {
        ...state,
        loading: false,
        flat: action.payload,
        error: null
      };
    case FETCH_FLAT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      case CLEAR_FLAT_STATE:
        return {
        ...state,
        flat: undefined,
      };
    default:
      return state;
  }
};

export default flatReducer;