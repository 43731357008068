import { combineReducers } from "redux";
import cardReducer from "./cardReducer";
import catalogReducer from "./catalogReducer";
import filterReducer from "./filterReducer";
import headerReducer from "./headerReducer";
import flatReducer from "./flatReducer";
import photosReducer  from "./photosReducer";
import appReducer  from "./appReducer";

const rootReducer = combineReducers({
    cards: cardReducer,
    catalog: catalogReducer,
    filter: filterReducer,
    header: headerReducer,
    flat: flatReducer,
    photos: photosReducer,
    app: appReducer,
  });
  
  export default rootReducer;