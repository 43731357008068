import axios from 'axios';
import {api_addr} from './urls';
import {getReviews} from '../actions/headerActions';

export const loadReviews = () => async (dispatch) => {
    const url = api_addr + `/reviews`;
    try{
        const reviewsResponse = await axios.get(url);
        const reviewsData = reviewsResponse.data;
        dispatch(getReviews(reviewsData));
    }
    catch (error) {
        // Обработка ошибок
    } 
};