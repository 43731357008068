import rootReducer from './reducers/index';
import {thunk} from 'redux-thunk';
import saveScreenPositionMiddleware from './middleware/appMiddleware';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { useCloudStorage } from '../hooks/useCloudStorage';
import { useTelegram } from '../hooks/useTelegram';

const configureStore = (cloudStorageResult, telegramResult) => {
    // Применяем middleware, передавая хуки в качестве параметров
    const middleware = applyMiddleware(thunk, saveScreenPositionMiddleware(cloudStorageResult, telegramResult));
    
    // Создаем магазин Redux с корневым редюсером и middleware
    const store = createStore(rootReducer, middleware);
    
    return store;
};

export default configureStore;