export const TOGGLE_FAVORITE_BUTTON = 'TOGGLE_FAVORITE_BUTTON';
export const OPEN_LINK = 'OPEN_LINK';
export const GET_REVIEWS = 'GET_REVIEWS';
export const SET_SHOW_ADDITIONAL_BUTTONS = 'SET_SHOW_ADDITIONAL_BUTTONS';
export const SET_SHOW_MAP = 'SET_SHOW_MAP';
export const SHOW_INFO_BTNS = 'SHOW_INFO_BTNS';
export const toggleFavoriteButton = (oldfavoriteButtonStatus) => ({
    type: TOGGLE_FAVORITE_BUTTON,
    payload: oldfavoriteButtonStatus,
});
export const openInfoLink = () => ({
    type: OPEN_LINK,
});
export const getReviews = (reviews) => ({
    type: GET_REVIEWS,
    payload: reviews,
});
export const setShowAdditionalButtons = (showAdditionalButtons) => {
    return {
        type: SET_SHOW_ADDITIONAL_BUTTONS,
        payload: showAdditionalButtons
    };
};

export const setShowMap = (showMap) => {
    return {
        type: SET_SHOW_MAP,
        payload: showMap
    };
};
export const showInfoBtns = () => {
    return {
        type: SHOW_INFO_BTNS,
    };
};
