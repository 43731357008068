import { FETCH_CARDS_REQUEST, FETCH_CARDS_SUCCESS, FETCH_CARDS_FAILURE, RESET_CARDS } from '../actions/cardActions';

const initialState = {
  loading: false,
  cards: [],
  error: '',
  offset: 0,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CARDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CARDS_SUCCESS:
      return {
        loading: false,
        cards: action.payload,
        error: '',
        offset: state.offset + action.payload.length,
      };
    case FETCH_CARDS_FAILURE:
      return {
        loading: false,
        cards: [],
        error: action.payload,
        offset: 0,
      };
    case RESET_CARDS:
      return {
        ...state,
        cards: [],
        offset: 0,
      };
    default:
      return state;
  }
};

export default cardReducer;