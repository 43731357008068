import {LOAD_PHOTOS_REQUEST, 
  LOAD_PHOTOS_SUCCESS, 
  LOAD_PHOTOS_FAILURE} from "../actions/photosActions";
const initialState = {
    photos: [],
    isLoading: false,
    error: null,
  };
  
const photosReducer  = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PHOTOS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LOAD_PHOTOS_SUCCESS:
      return {
          ...state,
          isLoading: false,
          paths: action.payload,
        };
    case LOAD_PHOTOS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default photosReducer;