export const text = {
    'en': {
        header:{
            info:'Info',
            favorite:'Favorite',
            forRealtors: 'For Realtors',
            forTenants: 'For Tenants',
            indi: 'INDIVIDUAL SELECTION',
            districts: 'Districts map'
        },
        card:{
            price_day:'AED/day',
            price_month:'AED/month',
            price_year:'AED/year'

        },
        filter:{
            rent:'Rent:',
            rent_period: 'Rent Period:',
            rent_daily: 'Daily',
            rent_monthly: 'Monthly',
            rent_yearly: 'Yearly',
            flat:'Flat',
            apartment:'Apartment',
            countRooms:'Count rooms:',
            location:'Location:',
            price:'Price:',
            price_day: 'Price:',
            price_month: 'Price:',
            price_year: 'Price:',
            from:'from',
            to:'to',
            conveniences: 'Conveniences:',
            rooms:{
                room: 'Room',
                bunk: 'Sleeping space',    
                studio: 'Studio',
                one_bedroom: '1 bedroom',
                two_bedroom: '2 bedroom',
                three_bedroom: '3 bedroom',
                village: 'Village'
            },
        },
        flat:{
            type:'Type',
            flat:'Flat',
            apartment:'Apartment',
            countRooms:'Count rooms',
            countGuests:'Count guests',
            floor_number:'Floor number',
            area:'Area',
            description:'Description',
            button:'ARRANGE SHOWING',
            icons:{
                is_balcony:'Balcony',
                is_wifi: 'Wi-Fi',
                is_car_charge: 'Electric car charge',
                is_pets: 'Accommodation with pets',
                is_conditioner: 'Air-conditioner',
                is_restaurant: 'Restaurant',
                is_tv: 'TV',
                is_kitchenette: 'Kitchenette',
                is_kitchen_utensils: 'Cooking utensils',
                is_pool: 'Pool',
                is_parking: 'Parking',
                is_bathroom: 'Bathroom',
                is_jacuzzi: 'Jacuzzi',
                is_family_apartment: 'Family Room',
                is_safe: 'Safe deposit',
                is_hair_dryer: 'Hair dryer',
                is_iron_for_clothes: 'Iron with ironing board',
                is_kettle: 'Kettle/coffee machine',
                is_fridge: 'Fridge',
                is_microwave: 'Microwave',
                is_daily_cleaning: 'Daily cleaning',
                is_gym: 'Gym',
                is_desktop: 'Desktop',
                is_near_subway: 'Near the subway',
            }
        },
        favorite:{
                askFavoriteBtn: 'ARRANGE SHOWING ALL'
        },
        rooms:{
            room: 'Room',
            bunk: 'Sleeping space',    
            studio: 'Studio',
            one_bedroom: '1 bedroom',
            two_bedroom: '2 bedroom',
            three_bedroom: '3 bedroom',
            village: 'Village'
        },
        shareFlat: {
            text: "I like this flat:",
            title: "Look at this flat"
        }
    },
    'ru': {
        header:{
            info:'Инфо',
            favorite:'Избранное',
            forRealtors: 'Риелторам',
            forTenants: 'Арендаторам',
            indi: 'ИНДИВИДУАЛЬНЫЙ ПОДБОР',
            districts: 'Карта районов'
        },
        card:{
            price_day:'AED/сут',
            price_month:'AED/месяц',
            price_year:'AED/год'

        },
        filter:{
            rent:'Снять:',
            rent_period: 'Срок снятия:',
            rent_daily: 'Сутки',
            rent_monthly: 'Месяц',
            rent_yearly: 'Год',
            flat:'Квартиру',
            apartment:'Апартаменты',
            countRooms:'Кол-во комнат:',
            location:'Район:',
            price:'Цена:',
            price_day: 'Price:',
            price_month: 'Price:',
            price_year: 'Price:',
            from:'от',
            to:'до',
            conveniences: 'Удобства:',
            rooms:{
                room: 'Комнату',
                bunk: 'Бедспейс/койка место',    
                studio: 'Студию',
                one_bedroom: '1 бедрум',
                two_bedroom: '2 бедрум',
                three_bedroom: '3 бедрум',
                village: 'Виллу'
            },
        },
        flat:{
            type:'Тип помещения',
            flat:'Квартира',
            apartment:'Апартаменты',
            countRooms:'Кол-во комнат',
            countGuests:'Кол-во гостей',
            floor_number:'Этаж',
            area:'Общая площадь',
            description:'Описание',
            button:'ДОГОВОРИТЬСЯ О ПОКАЗЕ',
            icons:{
                is_balcony:'Балкон',
                is_wifi: 'Wi-Fi',
                is_car_charge: 'Зарядка электромобиля',
                is_pets: 'Размещение с животными',
                is_conditioner: 'Кондиционер',
                is_restaurant: 'Ресторан (рядом)',
                is_tv: 'Телевизор с плоским экраном',
                is_kitchenette: 'Мини-кухня',
                is_kitchen_utensils: 'Кухонные принадлежности',
                is_pool: 'Бассейн',
                is_parking: 'Парковка',
                is_bathroom: 'Ванная',
                is_jacuzzi: 'Джакузи',
                is_family_apartment: 'Семейный номер',
                is_safe: 'Сейф',
                is_hair_dryer: 'Фен для волос',
                is_iron_for_clothes: 'Утюг с гладильной доской',
                is_kettle: 'Чайник/кофемашинка',
                is_fridge: 'Холодильник',
                is_microwave: 'Микроволновая печь',
                is_daily_cleaning: 'Ежедневная уборка',
                is_gym: 'Фитнес-центр',
                is_desktop: 'Рабочий стол',
                is_near_subway: 'Рядом с метро',
            }
        },
        favorite:{
            askFavoriteBtn: 'Договориться о показе всех'
        },
        rooms:{
            room: 'Комната',
            bunk: 'Бедспейс/койка место',    
            studio: 'Студия',
            one_bedroom: '1 бедрум',
            two_bedroom: '2 бедрум',
            three_bedroom: '3 бедрум',
            village: 'Вилла'
        },
        shareFlat: {
            text: "Мне понравилась эта квартира:",
            title: "Посмотри на эту квартиру"
        }
    }
}

const textToSort = {
    'en': {
        header:{
            info:'Info',
            favorite:'Favorite'
        },
        card:{
            price:'₽/day'
        },
        filter:{
            rent:'Rent:',
            flat:'Flat',
            apartment:'Apartment',
            countRooms:'Count rooms:',
            location:'Location:',
            price:'Price:',
            from:'from',
            to:'to'
        }
    },
    'ru': {
        header:{
            info:'Инфо',
            favorite:'Избранное'
        },
        card:{
            price:'₽/сут'
        },
        filter:{
            rent:'Снять:',
            flat:'Квартира',
            apartment:'Апартаменты',
            countRooms:'Кол-во комнат:',
            location:'Район:',
            price:'Цена:',
            from:'от',
            to:'до'
        }
    }
}
export default textToSort;