export const SET_RENT_TYPE = 'SET_RENT_TYPE';
export const SET_ROOMS_COUNT = 'SET_ROOMS_COUNT';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_RENT_PERIOD_TYPE = 'SET_RENT_PERIOD_TYPE';
export const SET_PRICE_RANGE = 'SET_PRICE_RANGE';
export const SET_CONVENIENCES = 'SET_CONVENIENCES';
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';
export const FETCH_RENT_TYPES = 'FETCH_RENT_TYPES';
export const FETCH_RENT_PERIOD_TYPES = 'FETCH_RENT_PERIOD_TYPES';
export const FETCH_ROOMS_COUNT = 'FETCH_ROOMS_COUNT';
export const FETCH_CONVENIENCES = 'FETCH_CONVENIENCES';
export const SET_FILTER_OPTION = 'SET_FILTER_OPTION';

export const setFilterOption = (filterId, options) => ({
  type: SET_FILTER_OPTION,
  payload: { filterId, options }
});

export const setRentType = (rentType) => ({
    type: SET_RENT_TYPE,
    payload: rentType,
  });
  
  export const setRoomsCount = (roomsCount) => ({
    type: SET_ROOMS_COUNT,
    payload: roomsCount,
  });
  
  export const setLocations = (locations) => ({
    type: SET_LOCATION,
    payload: locations,
  });
  
  export const setPriceRange = (range) => ({
    type: SET_PRICE_RANGE,
    payload: range,
  });
  export const fetchLocations = (locations) => ({
    type: FETCH_LOCATIONS,
    payload: locations,
  });
  export const fetchRentTypes = (rentTypes) => ({
    type: FETCH_RENT_TYPES,
    payload: rentTypes,
  });
  export const setRentPeriodType = (rentPeriodType) => ({
    type: SET_RENT_PERIOD_TYPE,
    payload: rentPeriodType,
  });
  export const fetchRentPeriodTypes = (rentPeriodTypes) => ({
    type: FETCH_RENT_PERIOD_TYPES,
    payload: rentPeriodTypes,
  });
  export const fetchRoomsCount = (roomsCount) => ({
    type: FETCH_ROOMS_COUNT,
    payload: roomsCount,
  });
  export const fetchConveniences = (conveniences) => ({
    type: FETCH_CONVENIENCES,
    payload: conveniences,
  });
  export const setConveniences = (conveniences) => ({
    type: SET_CONVENIENCES,
    payload: conveniences,
  });