export const SAVE_END_INDEX = 'SAVE_END_INDEX';
export const SAVE_SCROLL_TOP = 'SAVE_SCROLL_TOP';
export const GET_OPERATOR = 'GET_OPERATOR';
export const GET_OPERATOR_FAILURE = 'GET_OPERATOR_FAILURE';
export const SET_SELECT_FLAT = 'SET_SELECT_FLAT';
export const INIT_APP = 'INIT_APP';
export const BIND_BUTTONS = 'BIND_BUTTONS';
export const BIND_MAIN_BTN = 'BIND_MAIN_BTN';
export const BIND_BACK_BTN = 'BIND_BACK_BTN';
export const SET_WEBAPP_PLATFORM = 'SET_WEBAPP_PLATFORM';

export const initApp = () => ({
  type: INIT_APP
});
export const bindButtons = () => ({
  type: BIND_BUTTONS
});
export const bindMainBtn = () => ({
  type: BIND_MAIN_BTN
});
export const bindBackBtn = () => ({
  type: BIND_BACK_BTN
});
export const saveEndIndex = (endIndex) => ({
  type: SAVE_END_INDEX,
  payload: endIndex
}); 
export const saveScrollTop = (scrollTop) => ({
  type: SAVE_SCROLL_TOP,
  payload: scrollTop
});
export const getOperator = (operator) => ({
  type: GET_OPERATOR,
  payload: operator
});
export const getOperatorFailure = (error) => ({
  type: GET_OPERATOR_FAILURE,
  payload: error,
});
export const setSelectFlat = (id) => ({
  type: SET_SELECT_FLAT,
  payload: id
});
export const setWebAppPlatform = (platform) => ({
  type: SET_WEBAPP_PLATFORM,
  payload: platform
});