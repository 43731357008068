import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classes from "./Favorite.module.css";
import { updateFavorite  } from '../../store/thunks/favoriteThunks';

const Favorite = ({ id }) => {
  const dispatch = useDispatch();
  const isFavorite = useSelector((state) => {
                      const card = state?.catalog?.cards?.find(card => card.id === id);
                      return card ? card.isFavorite : undefined;
                      });;
  const pressLike = () => {
    dispatch(updateFavorite(id, isFavorite)); // Передаем id карточки и инвертированное значение isFavorite
  };

  return (
    <>
      {isFavorite ? (
        <svg
          className={classes.like}
          onClick={(e) => {
            e.stopPropagation();
            pressLike();
          }}
          opacity={1}
          width="26"
          height="24"
          viewBox="0 0 26 24"
          fill="var(--button-color)"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15.7517 0.59292C16.6526 0.201824 17.6195 0 18.597 0C19.5744 0 20.5413 0.201824 21.4422 0.59292C22.3428 0.983903 23.1586 1.55588 23.8441 2.27428C24.5299 2.99246 25.072 3.84313 25.4412 4.77678C25.8104 5.71037 26 6.70978 26 7.71824C26 8.7267 25.8104 9.72612 25.4412 10.6597C25.072 11.5933 24.53 12.4439 23.8443 13.162L13.8484 23.6351C13.6258 23.8684 13.3196 24 12.9997 24C12.6798 24 12.3736 23.8684 12.151 23.6351L2.15512 13.162C0.77118 11.712 0 9.75314 0 7.71824C0 5.68334 0.77118 3.72449 2.15512 2.27448C3.54029 0.823184 5.42702 0.000633574 7.40245 0.000633574C9.37787 0.000633574 11.2646 0.823184 12.6498 2.27448L12.9997 2.64112L13.3494 2.27468C14.0349 1.55619 14.851 0.98394 15.7517 0.59292Z" fill="var(--button-color)" />
        </svg>
      ) : (
        <svg
          className={classes.like}
          onClick={(e) => {
            e.stopPropagation();
            pressLike();
          }}
          opacity={1}
          width="26"
          height="24"
          viewBox="0 0 26 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15.7517 0.59292C16.6526 0.201824 17.6195 0 18.597 0C19.5744 0 20.5413 0.201824 21.4422 0.59292C22.3428 0.983903 23.1586 1.55588 23.8441 2.27428C24.5299 2.99246 25.072 3.84313 25.4412 4.77678C25.8104 5.71037 26 6.70978 26 7.71824C26 8.7267 25.8104 9.72612 25.4412 10.6597C25.072 11.5933 24.53 12.4439 23.8443 13.162L13.8484 23.6351C13.6258 23.8684 13.3196 24 12.9997 24C12.6798 24 12.3736 23.8684 12.151 23.6351L2.15512 13.162C0.77118 11.712 0 9.75314 0 7.71824C0 5.68334 0.77118 3.72449 2.15512 2.27448C3.54029 0.823184 5.42702 0.000633574 7.40245 0.000633574C9.37787 0.000633574 11.2646 0.823184 12.6498 2.27448L12.9997 2.64112L13.3494 2.27468C14.0349 1.55619 14.851 0.98394 15.7517 0.59292ZM22.1467 3.94451C21.6772 3.45231 21.1217 3.06393 20.513 2.79968C19.9044 2.53547 19.2534 2.4 18.597 2.4C17.9405 2.4 17.2895 2.53547 16.6809 2.79968C16.0722 3.06393 15.5167 3.45231 15.0472 3.94451L13.8484 5.20054C13.6258 5.43377 13.3196 5.56542 12.9997 5.56542C12.6798 5.56542 12.3736 5.43377 12.151 5.20054L10.9524 3.94471C10.0044 2.95143 8.72665 2.40063 7.40245 2.40063C6.07825 2.40063 4.80049 2.95143 3.85247 3.94471C2.90323 4.93928 2.36364 6.29599 2.36364 7.71824C2.36364 9.14049 2.90323 10.4972 3.85247 11.4918L12.9997 21.0757L22.1469 11.4918C22.6168 10.9997 22.9916 10.4133 23.2478 9.76538C23.504 9.11744 23.6364 8.4217 23.6364 7.71824C23.6364 7.01478 23.504 6.31905 23.2478 5.6711C22.9916 5.02321 22.6166 4.43656 22.1467 3.94451Z" fill="var(--button-color)" />
        </svg>
      )}
    </>
  );
};

export default Favorite;