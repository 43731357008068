import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFavoriteButton, setShowAdditionalButtons, setShowMap, showInfoBtns } from '../../store/actions/headerActions';
import classes from "./Header.module.css";
import {useTelegram} from "../../hooks/useTelegram";
import { useCloudStorage } from '../../hooks/useCloudStorage';
import {text} from "../../translate";
import {getConsultation} from "../../store/thunks/appThunks";
import {loadReviews} from '../../store/thunks/headerThunks';
import districtImg from "../../image/districts.jpg";
import ImageSlider from "../ImageSlider/ImageSlider"; 

const Header = () => {
    const dispatch = useDispatch();
    const {lang, webApp} = useTelegram();
    const {getItemAsync} = useCloudStorage();
    const favoriteButtonPressed = useSelector(state => state.header.favoriteButtonPressed);
    const showAdditionalButtons = useSelector(state => state.header.showAdditionalButtons);
    const showMap = useSelector(state => state.header.showMap);
    //const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);
    const realtorLink = 'https://mapster.notion.site/INFO-84f59f6f5fab4ec4be7bf1d8b629563b';
    const tenantLink = 'https://mapster.notion.site/INFO-1-126b16da448843e088bc7de8359d8aa2?pvs=25';
    //const [showMap, setShowMap] = useState(false);
    const reviews = useSelector(state => state?.header?.reviews);
    const pressSetFavorite = () => {
        dispatch(toggleFavoriteButton(favoriteButtonPressed));
    };
    const pressShowInfo = () => {
        dispatch(showInfoBtns());
        dispatch(loadReviews());
        dispatch(setShowAdditionalButtons(!showAdditionalButtons));
        dispatch(setShowMap(false));
    };
    const pressIndividualBtn = () => {
        getConsultation(webApp, getItemAsync);
    };
    const handleClick = (link) => {
        window.open(link, '_blank'); 
    };
    const handleMapClick = () => {
        dispatch(setShowMap(!showMap));
    };
    const handleBlurClick = (e) => {
        if (e.target.className === classes.blur) {
            dispatch(setShowAdditionalButtons(false));
            dispatch(setShowMap(false));
            webApp.BackButton.hide();
        }
    };
    return (
        <div className={classes._}>
            <div className={classes.buttonGroup}>
                <div className={classes.button} onClick={pressShowInfo}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="var(--button-color)"/>
                        <path d="M11.9854 8.64062C12.6191 8.64062 13.1328 9.15436 13.1328 9.78809V18.3037C13.1328 18.9374 12.6191 19.4512 11.9854 19.4512C11.3516 19.4512 10.8379 18.9374 10.8379 18.3037V9.78809C10.8379 9.15436 11.3516 8.64062 11.9854 8.64062ZM12 4.5C12.3516 4.5 12.6543 4.5944 12.9082 4.7832C13.1686 4.97201 13.2988 5.29753 13.2988 5.75977C13.2988 6.21549 13.1686 6.54102 12.9082 6.73633C12.6543 6.92513 12.3516 7.01953 12 7.01953C11.6354 7.01953 11.3262 6.92513 11.0723 6.73633C10.8249 6.54102 10.7012 6.21549 10.7012 5.75977C10.7012 5.29753 10.8249 4.97201 11.0723 4.7832C11.3262 4.5944 11.6354 4.5 12 4.5Z" fill="var(--bg_color)"/>
                    </svg>
                    {text[lang].header.info}
                </div>
                <div className={`${classes.button} ${favoriteButtonPressed && classes.buttonSelect}`} onClick={pressSetFavorite}>
                    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.7517 0.59292C16.6526 0.201824 17.6195 0 18.597 0C19.5744 0 20.5413 0.201824 21.4422 0.59292C22.3428 0.983903 23.1586 1.55588 23.8441 2.27428C24.5299 2.99246 25.072 3.84313 25.4412 4.77678C25.8104 5.71037 26 6.70978 26 7.71824C26 8.7267 25.8104 9.72612 25.4412 10.6597C25.072 11.5933 24.53 12.4439 23.8443 13.162L13.8484 23.6351C13.6258 23.8684 13.3196 24 12.9997 24C12.6798 24 12.3736 23.8684 12.151 23.6351L2.15512 13.162C0.77118 11.712 0 9.75314 0 7.71824C0 5.68334 0.77118 3.72449 2.15512 2.27448C3.54029 0.823184 5.42702 0.000633574 7.40245 0.000633574C9.37787 0.000633574 11.2646 0.823184 12.6498 2.27448L12.9997 2.64112L13.3494 2.27468C14.0349 1.55619 14.851 0.98394 15.7517 0.59292Z" fill={favoriteButtonPressed?'var(--button-text-color)':'var(--button-color)'}/>
                    </svg>
                    {text[lang].header.favorite}
                </div>
                <div className={classes.buttonWide} onClick={pressIndividualBtn}>
                    {text[lang].header.indi}
                </div>
            </div>
            {showAdditionalButtons && (
                <div className={classes.blur} onClick={handleBlurClick}>
                    {!showMap && (
                        <div className={classes.popWindow}>
                            <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z" fill="var(--button-color)"/>
                                <path d="M11.9854 8.64062C12.6191 8.64062 13.1328 9.15436 13.1328 9.78809V18.3037C13.1328 18.9374 12.6191 19.4512 11.9854 19.4512C11.3516 19.4512 10.8379 18.9374 10.8379 18.3037V9.78809C10.8379 9.15436 11.3516 8.64062 11.9854 8.64062ZM12 4.5C12.3516 4.5 12.6543 4.5944 12.9082 4.7832C13.1686 4.97201 13.2988 5.29753 13.2988 5.75977C13.2988 6.21549 13.1686 6.54102 12.9082 6.73633C12.6543 6.92513 12.3516 7.01953 12 7.01953C11.6354 7.01953 11.3262 6.92513 11.0723 6.73633C10.8249 6.54102 10.7012 6.21549 10.7012 5.75977C10.7012 5.29753 10.8249 4.97201 11.0723 4.7832C11.3262 4.5944 11.6354 4.5 12 4.5Z" fill="var(--bg_color)"/>
                            </svg>
                            <div className={classes.popText}>
                            Приветствуем вас на нашем сервисе!<br/>Для удобства подготовили ссылки с полезной информацией о нашем продукте
                            </div>
                            <div className={classes.popbuttonGroup}>
                                <div className={classes.popbutton} onClick={() => handleClick(realtorLink)}>
                                    {text[lang].header.forRealtors}
                                </div>
                                <div className={classes.popbutton} onClick={() => handleClick(tenantLink)}>
                                    {text[lang].header.forTenants}
                                </div>
                                <div className={classes.popbutton} onClick={() => handleMapClick()}>
                                    {text[lang].header.districts}
                                </div>
                            </div>
                            <div className={classes.sliderBlock}><ImageSlider photos={reviews} /></div>
                        </div>                            
                    )}
                    {showMap && (
                        <div className={classes.mapContainer}>
                            <img
                                src={districtImg}
                                alt="Map"
                                className={classes.mapImage}
                                onClick={(e) => e.stopPropagation()} 
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Header;