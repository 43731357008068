import {INIT_APP, 
  BIND_BUTTONS,
  BIND_MAIN_BTN,
  BIND_BACK_BTN,
  SAVE_END_INDEX, 
  SAVE_SCROLL_TOP, 
  SAVE_SCROLL_HEIGHT,
} from '../actions/appActions';
import {OPEN_LINK, TOGGLE_FAVORITE_BUTTON, SHOW_INFO_BTNS, setShowAdditionalButtons} from '../actions/headerActions';
import {FETCH_FLAT_REQUEST} from '../actions/flatActions';
import {SHOW_CATALOG} from '../actions/catalogActions';
import {text} from "../../translate";
import {sendTrack} from "../thunks/stat";
import {openChatPopup, selectFlat, postShowingAll, getCurrentOperator} from '../thunks/appThunks';

const saveScreenPositionMiddleware = (useCloudStorageResult, useTelegramResult) => {
  
  let arrangeShowingHandler = null;
  let arrangeShowingAllHandler = null;
  let backToCatalogHandler = null;

  return store => next => action => {
  const { dispatch, getState } = store;
  const { setItem, getItemAsync } = useCloudStorageResult;  
  const { webApp, lang } = useTelegramResult;
  
  const backToCatalog = () => {
    const state = getState();
    const reviews = state?.header?.reviews;
    if(reviews){
      dispatch(setShowAdditionalButtons(false));
      webApp.BackButton.hide();
      dispatch(selectFlat(-1));
      webApp.BackButton.hide();
    }
    else{
      dispatch(selectFlat(-1));
      webApp.BackButton.hide();
    }
  };
  const arrangeShowingAll = async (webApp, favoriteIds, getItemAsync) => {
    const btnFlag = await getItemAsync('MainBtn');
    if (btnFlag === 'ShowingAll'){
      postShowingAll(webApp, favoriteIds, getItemAsync);
    }
  };
  
  const arrangeShowing =  async (webApp, getItemAsync) => {
    const btnFlag = await getItemAsync('MainBtn');
    if (btnFlag === 'Showing'){
      openChatPopup(webApp, getItemAsync);
    }
  };

  const state = getState();
  const favoriteIds = state.catalog.favorites;

  const handleArrangeShowing = () => arrangeShowingHandler && arrangeShowingHandler();
  const handleArrangeShowingAll = () => arrangeShowingAllHandler && arrangeShowingAllHandler();
  const handleBackToCatalog = () => backToCatalogHandler && backToCatalogHandler();
  
  arrangeShowingHandler = () => arrangeShowing(webApp, getItemAsync);
  arrangeShowingAllHandler = () => arrangeShowingAll(webApp, favoriteIds, getItemAsync);
  backToCatalogHandler = backToCatalog;

  if (action){
    console.log(action?.type);
    switch (action?.type) {
        case SAVE_END_INDEX:
          // Обновляем значение endIndex в хранилище и в CloudStorage
          localStorage.setItem('endIndex', action.payload);
          setItem('endIndex', action.payload);
          break;
        case SAVE_SCROLL_TOP:
          // Обновляем значение scrollTop в хранилище и в CloudStorage
          localStorage.setItem('scrollTop', action.payload);
          setItem('scrollTop', action.payload);
          break;
        case INIT_APP:
          webApp.ready();
          webApp.expand();
          webApp.MainButton.hide();
          webApp.BackButton.hide();
          sendTrack(webApp); 
          dispatch(getCurrentOperator(setItem));
          break;
        case BIND_BUTTONS:
          break;
        case BIND_MAIN_BTN:
          webApp.MainButton.onClick(handleArrangeShowing);
          webApp.MainButton.onClick(handleArrangeShowingAll);
          break;
        case BIND_BACK_BTN:
          webApp.BackButton.onClick(handleBackToCatalog);
          break;
        case FETCH_FLAT_REQUEST:
          setItem('MainBtn', 'Showing');
          webApp.MainButton.setText(text[lang].flat.button);
          webApp.MainButton.show();
          webApp.BackButton.show();
          break;
        case SHOW_CATALOG:
          webApp.MainButton.hide();
          webApp.BackButton.hide();
          break;
        case TOGGLE_FAVORITE_BUTTON:
          const favotiteBtn = action.payload;
          if(!favotiteBtn){
            if (favoriteIds.length > 0){
              setItem('MainBtn', 'ShowingAll');
              webApp.MainButton.setText(text[lang].favorite.askFavoriteBtn);
              webApp.MainButton.show();
            }
          }
          else{
            webApp.MainButton.hide();
          }  
          break;
        case SHOW_INFO_BTNS:
          webApp.BackButton.show();
          break;
        default:
          break;
      }
      return next(action);
    }
  };
};

     
  export default saveScreenPositionMiddleware;