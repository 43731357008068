import axios from 'axios';
import {api_addr} from './urls';
import { useTelegram } from '../../hooks/useTelegram'; 
import {toggleFavorite} from '../actions/catalogActions';

// Action Creator для переключения избранного
export const updateFavorite = (cardId, isFavorite) => async (dispatch) => {
  try {
    const {webApp} = useTelegram();  
    const telegramId = webApp.initDataUnsafe?.user?.id.toString();
    // Определяем URL для добавления или удаления избранного
    const url = api_addr + `/apartments/likes`;

    const data = {
      apartment_id: cardId,
      client_telegram_id: telegramId
    };

    // Отправляем соответствующий HTTP-запрос
    if (!isFavorite) {
      // Отправляем POST запрос для добавления в избранное
      await axios.post(url, data);
    } else {
      // Отправляем DELETE запрос для удаления из избранного
      await axios.delete(url, {data: data});
    }

    // Если запрос выполнен успешно, диспатчим экшен TOGGLE_FAVORITE
    dispatch(toggleFavorite(cardId));
  } catch (error) {
    console.error('Ошибка при выполнении запроса:', error);
    // Обработка ошибок, если необходимо
  }
};