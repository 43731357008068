import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classes from "./Flat.module.css";
import Favorite from '../favorite/Favorite';
import ImageSlider from '../imageSlider/ImageSlider';
import ShareButton from '../shareButton/ShareButton';
import {text} from "../../translate";
import {useTelegram} from "../../hooks/useTelegram";
import {useIcons} from "../icons/Icons";
import {fetchFlat, fetchFlatTest} from "../../store/thunks/flatThunks";
import {useCloudStorage} from '../../hooks/useCloudStorage';

const Flat = ({id}) => {
    const dispatch = useDispatch();
    const {setItem} = useCloudStorage();
    const {lang, webApp} = useTelegram();
    const icons = useIcons();
    const flat = useSelector(state => state.flat?.flat);
    const loading = useSelector(state => state.flat?.loading);
    const rentPeriodTypeFilter = useSelector(state => state?.filter?.selectedRentPeriodType);
    const [rentPeriodTypePrice, setRentPeriodTypePrice] = useState(flat?.price_month);
    const [priceSubscription, setPriceSubscription] = useState(text[lang].card.price_month);

    const rooms = [
        {id:1, name: text[lang].rooms.room, ru_id: text['ru'].rooms.room},
        {id:2, name: text[lang].rooms.bunk, ru_id: text['ru'].rooms.bunk},
        {id:3, name: text[lang].rooms.studio, ru_id: text['ru'].rooms.studio},
        {id:4, name: text[lang].rooms.one_bedroom, ru_id: text['ru'].rooms.one_bedroom},
        {id:5, name: text[lang].rooms.two_bedroom, ru_id: text['ru'].rooms.two_bedroom},
        {id:6, name: text[lang].rooms.three_bedroom, ru_id: text['ru'].rooms.three_bedroom},
        {id:7, name: text[lang].rooms.village, ru_id: text['ru'].rooms.village},
    ];

    useEffect(() => {
        if(rentPeriodTypeFilter){
            switch (rentPeriodTypeFilter ? rentPeriodTypeFilter[0]?.id : null){
                case 0:
                    setRentPeriodTypePrice(flat?.price_day_t);
                    setPriceSubscription(text[lang].card.price_day);
                    break;
                case 1:
                    setRentPeriodTypePrice(flat?.price_month_t);
                    setPriceSubscription(text[lang].card.price_month);
                    break;
                case 2:
                    setRentPeriodTypePrice(flat?.price_year_t);
                    setPriceSubscription(text[lang].card.price_year);
                    break;
                default: 
                    break;
            }
        }
      }, [dispatch, rentPeriodTypeFilter, flat]);
    
    useEffect(() => {
        //dispatch(fetchFlatTest());
        dispatch(fetchFlat(setItem, id));
      }, [dispatch]);

    return (
        <div className={`${classes.container} ${!loading ? classes.visible : ''}`}>
        {!loading && (
        <div className={classes._}>
            <div className={classes.block1}>
            <ImageSlider photos={flat?.photos} />
                <div className={classes.infoBlock}>
                    <div className={classes.name}>{flat?.title}</div>
                    <div className={classes.location}>{flat?.location?.name}</div>
                    <div className={classes.block1_2}>
                        <div className={classes.address}>{flat?.address}</div>
                        {/*<div className={classes.price}>{rentPeriodTypePrice} {priceSubscription}</div>*/}
                        <div className={classes.priceInfo}>
                            <p className={classes.id}>id: {flat?.id}</p>
                            <div className={classes.price}>{rentPeriodTypePrice} {priceSubscription}</div>
                        </div>
                    </div>
                    <ShareButton id={flat?.id} imageUrl={flat?.photos && flat?.photos.length > 0 ? flat.photos[0]?.file_path : undefined}/>
                    <Favorite id={flat?.id} />
                </div>
            </div>
            <div className={classes.block1_5}>
                {/*<div className={classes.block1_5_1}>
                    <div className={classes.infoText5}> 
                        {flat?.price_day !== null ? `${flat.price_day_t}` : '---'}
                    </div>
                    <div className={classes.infoText6}> 
                        {text[lang].card.price_day}
                    </div>
        </div>*/}
                <div className={classes.block1_5_1}>
                    <div className={classes.infoText5}>
                        {flat?.price_month !== null ? `${flat.price_month_t}` : '---'}
                    </div>
                    <div className={classes.infoText6}>
                        {text[lang].card.price_month}
                    </div>
                </div>
                <div className={classes.block1_5_1}>
                    <div className={classes.infoText5}>
                        {flat?.price_year !== null ? `${flat.price_year_t}` : '---'}
                    </div>
                    <div className={classes.infoText6}>
                        {text[lang].card.price_year}
                    </div>
                </div>
            </div>
            <div className={classes.block2}>
                <div className={classes.block2_1}>
                    <div className={classes.infoText}>{text[lang].flat?.type}</div>
                    <div className={classes.infoText2}>{rooms.find(i => i?.ru_id === flat?.room?.value)?.name}</div>
                    {/*<div className={classes.infoText2}>{flat?.type === 1 ? text[lang].flat.apartment : text[lang].flat.flat }</div>*/}
                </div>
                {/*<div className={classes.block2_1}>
                    <div className={classes.infoText}>{text[lang].flat.countRooms}</div>
                    <div className={classes.infoText2}>{flat?.room?.value}</div>
                </div>*/}
                {/*<div className={classes.block2_1}>
                    <div className={classes.infoText}>{text[lang].flat.floor_number}</div>
                    {flat?.floor_number ? <div className={classes.infoText2}>{flat?.floor_number}/{flat?.number_of_floors}</div> :
                        <div className={classes.infoText2}>-</div>
                    }
                </div>*/}
                <div className={classes.block2_1}>
                    <div className={classes.infoText}>{text[lang].flat.area}</div>
                    {flat?.total_area ? <div className={classes.infoText2}>{flat?.total_area} м<sup>2</sup></div> :
                        <div className={classes.infoText2}>-</div>
                    }
                </div>
            </div>
            <div className={classes.block3}>
                <div className={classes.infoText3}>{text[lang].flat.description}</div>
                <div className={classes.infoText4} dangerouslySetInnerHTML={{__html: flat?.description}}></div>
                <div className={classes.block3_1}>
                    {icons.filter(item => item.name && flat && flat[item?.name]).map(item =>
                        <div key={item?.id} className={classes.iconBlock}>
                            <img src={item?.image} className={classes.icon}/>
                            {text[lang].flat.icons[item?.name]}
                        </div>)}
                </div>
            </div>
        </div>
        )}
        </div>
    );
};

export default Flat;