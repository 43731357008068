import axios from 'axios';
import {api_addr} from './urls';
import {initApp,
    bindButtons,
    bindMainBtn,
    bindBackBtn,
    getOperator,
    getOperatorFailure,
    setSelectFlat,
    setWebAppPlatform} from '../actions/appActions';

export const getCurrentOperator = (setItem) => async (dispatch) => {
    const url = api_addr + `/operator`;
    try{
        const operatorResponse = await axios.get(url);
        const operatorData = operatorResponse.data;
        //Потенциально нужно, если смотреть просто в браузере 
        //localStorage.setItem('Operator', operatorData.telegram_username);
        setItem('cloudOperator', operatorData.telegram_username);
        dispatch(getOperator(operatorData));
    }
    catch (error) {
        // Обработка ошибок
        dispatch(getOperatorFailure(error.message));
      }
  };

export const openChatPopup = async (webApp, getItemAsync) => {
    // Определяем текст и кнопки для всплывающего окна
    const oper = await getItemAsync('cloudOperator');
    var text = `🔜 Вы сейчас будете перенаправлены в чат с менеджером\n✉️ Отправьте ему любое сообщение, чтобы с вами смогли связаться`;
    if(!webApp.initDataUnsafe?.user?.username || webApp.initDataUnsafe?.user?.username === ''){
        text = `Так как у вас нет username, отправьте менеджеру любое сообщение, чтобы с вами смогли связаться`;
    }    
    const params = {
        message: text,
        buttons: [
            {
                id: '13',
                text: "Перейти в чат с менеджером",
            },
        ],
    }; 
    const flatId = await getItemAsync('cloudFlatId');
    

    const telegramId = webApp.initDataUnsafe?.user?.id.toString();

    //Потенциально нужно, если смотреть просто в браузере 
    //const oper = localStorage.getItem('Operator');
    //const flatId = localStorage.getItem('FlatId');

    
    const urlStatistics = api_addr + `/apartments/showings`;
    
    //Пока без него
    //TODO получать с сервера slug объекта
    const slug = 10;
    
    const messageTemplate = `Лид: @${webApp.initDataUnsafe?.user?.username} Ссылка на объект: t.me/Tg_estate_bot/app?startapp=${flatId}`;
    const operator_url = `https://t.me/${oper}?text=${messageTemplate}`;

    const data = {
        apartment_id: parseInt(flatId),
        client_telegram_id: telegramId,
        is_accepted: true
      };
    // Показываем всплывающее окно
    webApp.showPopup(params, async (action) => {
        // Обработчик события, вызываемого при нажатии на кнопку во всплывающем окне
        if(action === params?.buttons[0]?.id){
            await axios.post(urlStatistics,data);
            webApp.openTelegramLink(operator_url);
        }
    });
};

export const selectFlat = (id) => async (dispatch) => {
    dispatch(setSelectFlat(id));
};

export const postShowingAll = async (webApp, favoriteIds, getItemAsync) => {
    const telegramId = webApp.initDataUnsafe?.user?.id.toString();
    const url = api_addr + `/apartments/showings_many`;
    const oper = await getItemAsync('cloudOperator');
    const stringOfFavoritesIds = favoriteIds.join(',');
    const messageTemplate = `Здравствуйте, меня интересуют варианты жилья с номерами ${stringOfFavoritesIds}.`;
    const operator_url = `https://t.me/${oper}?text=${messageTemplate}`;

    const data = {
        client_telegram_id: telegramId,
        apartment_ids: favoriteIds
      };
    const params = {
        message: `🔜 Вы сейчас будете перенаправлены в чат с менеджером\n✉️ Отправьте ему любое сообщение, чтобы с вами смогли связаться`,
        buttons: [
            {
                id: '13',
                text: "Перейти в чат с менеджером",
            },
        ],
    }; 

    webApp.showPopup(params, async (action) => {
        // Обработчик события, вызываемого при нажатии на кнопку во всплывающем окне
        if(action === params?.buttons[0]?.id){
            await axios.post(url,data);
            webApp.openTelegramLink(operator_url);
        }
    });
};

export const getConsultation = async (webApp, getItemAsync) => {
    const oper = await getItemAsync('cloudOperator');
    const messageTemplate = `Здравствуйте. Хочу индивидуальный подбор жилья 🏠`;
    const operator_url = `https://t.me/${oper}?text=${messageTemplate}`;

    const params = {
        message: `🔜 Вы сейчас будете перенаправлены в чат с администратором\n✉️ Отправьте ему любое сообщение, чтобы с вами смогли связаться`,
        buttons: [
            {
                id: '10',
                text: "Перейти в чат",
            },
        ],
    }; 

    webApp.showPopup(params, async (action) => {
        // Обработчик события, вызываемого при нажатии на кнопку во всплывающем окне
        if(action === params?.buttons[0]?.id){
            webApp.openTelegramLink(operator_url);
        }
    });
};


export const initAppAndButtons = () => async (dispatch) => {
    const hash = window.location.hash.slice(1);
    const params = new URLSearchParams(hash);
    const tgWebAppData = new URLSearchParams(params.get('tgWebAppData'));
    const flat = tgWebAppData.get('start_param');

    const tgWebAppPlatformRegex = /tgWebAppPlatform=([^&]*)/;
    const matchTgWebAppPlatform = hash.match(tgWebAppPlatformRegex);
    
    dispatch(initApp());
    dispatch(bindButtons());
    dispatch(bindMainBtn());
    dispatch(bindBackBtn());

    if (matchTgWebAppPlatform) {
        const tgWebAppPlatform = matchTgWebAppPlatform[1];
        dispatch(setWebAppPlatform(tgWebAppPlatform));
    } else {
        console.log('Значение tgWebAppPlatform не найдено в hash.');
    }

    if(flat && parseInt(flat) > -1){
        dispatch(setSelectFlat(flat));
    }  
}