import axios from 'axios';
import {api_addr} from './urls';

async function track(name,telegram,id_telegram){
    const url = api_addr + '/telegram_client';
    const data = {
        client_id: id_telegram.toString(),
        username: telegram ? telegram : 'Нет Юзернейма',
        name: name
    };
    await axios.post(url,data);
    
}

export const sendTrack = async (webApp)=>{
    if(webApp.initDataUnsafe?.user?.id !== undefined){
      const res = await track(
          webApp.initDataUnsafe?.user?.first_name+' '+webApp.initDataUnsafe?.user?.last_name,
          webApp.initDataUnsafe?.user?.username,
          webApp.initDataUnsafe?.user?.id
      );
    }
  };
