export const LOAD_PHOTOS_REQUEST = 'LOAD_PHOTOS_REQUEST';
export const LOAD_PHOTOS_SUCCESS = 'LOAD_PHOTOS_SUCCESS';
export const LOAD_PHOTOS_FAILURE = 'LOAD_PHOTOS_FAILURE';
export const loadPhotos = () => ({
    type: LOAD_PHOTOS_REQUEST,
  });
  export const loadPhotosSuccess = (pathsToLoadedPhotos) => ({
    type: LOAD_PHOTOS_SUCCESS,
    payload: pathsToLoadedPhotos,
  });
  export const loadPhotosFailure = (error) => ({
    type: LOAD_PHOTOS_FAILURE,
    payload: error,
  });
  