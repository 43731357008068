import {TOGGLE_FAVORITE_BUTTON, OPEN_LINK, GET_REVIEWS, SET_SHOW_ADDITIONAL_BUTTONS, SET_SHOW_MAP} from '../actions/headerActions';
const initialState = {
    favoriteButtonPressed: false,
    reviews: null,
    showAdditionalButtons: false,
    showMap: false,
};

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_FAVORITE_BUTTON:
            return {
                ...state,
                favoriteButtonPressed: !state.favoriteButtonPressed,
            };
        case OPEN_LINK:
            return {
                ...state,
            };
        case GET_REVIEWS:
            return {
                ...state,
                reviews: action.payload,
            }
        case SET_SHOW_ADDITIONAL_BUTTONS:
            return {
                ...state,
                showAdditionalButtons: action.payload,
            }
        case SET_SHOW_MAP:
            return {
                ...state,
                showMap: action.payload,
            }
        default:
            return state;
    }
};

export default headerReducer;