import {
  FETCH_CARDS_REQUEST,
  FETCH_CARDS_SUCCESS,
  FETCH_CARDS_FAILURE,
  MARK_FAVORITES,
  APPLY_FILTERS,
  TOGGLE_FAVORITE,
  SHOW_CATALOG
} from '../actions/catalogActions';

const initialState = {
  loading: false,
  error: '',
  cards: null,
  filteredCards: null,
  favorites: [], 
  locations: [],
  filterFlags: {rentTypeFilterFlag: false,
                roomsFilterFlag: false,
                locationFilterFlag: false,
                priceFilterFlag: false,
  },
  };
  
  const catalogReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_CARDS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_CARDS_SUCCESS:
      const loc = action.payload?.map(card => card?.location.name);
      const uniqueLocations = [...new Set(loc)];
      const premiumCards = action.payload?.filter(card => card?.is_premium);
      const shuffledPremiumCards = shuffleArray(premiumCards);
      const topTwoPremiumCards = shuffledPremiumCards?.length > 0 ? shuffledPremiumCards.slice(0, 2) : [];
      const topTwoOrderIds = topTwoPremiumCards?.length > 0 ? topTwoPremiumCards?.map(card => card.order_id) : [];
      const remainingCards = topTwoOrderIds?.length > 0 ? action.payload?.filter(card => !topTwoOrderIds?.includes(card?.order_id)) : action.payload;
      const finalCards = [...topTwoPremiumCards, ...remainingCards];

      return {
          ...state,
          loading: false,
          cards: finalCards,
          filteredCards: finalCards,
          locations: uniqueLocations,
        };
      case FETCH_CARDS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case MARK_FAVORITES:
        const updatedCards = state.cards.map(card => ({
          ...card,
          isFavorite: action.payload.includes(card.id), // Помечаем карточки в избранных
        }));
        return {
          ...state,
          favorites: action.payload,
          cards: updatedCards,
          filteredCards: updatedCards, //Обновляем весь список
        };
      case APPLY_FILTERS: 
        const { rentType, roomsCount, locations, priceRange, rentPeriodType, conveniences } = action.payload;
        const rentPeriodTypeId = rentPeriodType ? rentPeriodType[0]?.id : null;
        const filteredCards = state.cards?.filter(card => {
          //Фильтрация по периоду
          switch (rentPeriodTypeId){
            case 0:
              if (card?.price_day_t === null || card?.price_day_t === undefined || card?.price_day_t <= 0){
                return false;
              }
              break;
            case 1:
              if (card?.price_month_t === null || card?.price_month_t === undefined || card?.price_month_t <= 0){
                return false;
              }
              break;
            case 2:
              if (card?.price_year_t === null || card?.price_year_t === undefined || card?.price_year_t <= 0){
                return false;
              }
              break;
            default:
              break;
          }
          // Фильтрация по типу аренды
          //if (rentType && rentType.length > 0 && !rentType.some(item => item?.id === card.apartment_type)) {
           // return false;
          //}
          // Фильтрация по местоположению
          if (locations && locations.length > 0 && !locations.some(item => item?.name === card?.location?.name)) {
            return false;
          }
          // Фильтрация по цене
          let price;
          switch (rentPeriodTypeId) {
              case 0:
                  price = card?.price_day_t;
                  break;
              case 1:
                  price = card?.price_month_t;
                  break;
              case 2:
                  price = card?.price_year_t;
                  break;
              default:
                  price = card.price_month_t;
                  break;
          }
          if((priceRange?.from && (price < priceRange?.from)) || (priceRange?.to && (price > priceRange?.to))){
            return false;
          }
          // Фильтрация по количеству комнат
          if (roomsCount && 
            roomsCount.length > 0 && 
            !roomsCount.some(item => {
              if (item?.id){
                //return item.id === card?.room?.value;
                return item.ru_id === card?.room?.value;
              }                         
            })) 
          {
            return false;
          }
          //Фильтрация по удобствам
          if(conveniences && conveniences.length > 0 && !conveniences.every(item => card?.options_list?.includes(item?.id))){
            return false;
          }
          return true;
        });
        const premiumFilteredCards = filteredCards?.filter(card => card?.is_premium);
        const shuffledFilteredPremiumCards = shuffleArray(premiumFilteredCards);
        const topTwoFilteredPremiumCards = shuffledFilteredPremiumCards?.length > 0 ? shuffledFilteredPremiumCards.slice(0, 2) : [];
        const topTwoFilteredOrderIds = topTwoFilteredPremiumCards?.length > 0 ? topTwoFilteredPremiumCards.map(card => card.order_id) : [];
        const remainingFilteredCards = topTwoFilteredOrderIds?.length > 0 ? filteredCards?.filter(card => !topTwoFilteredOrderIds.includes(card.order_id)) : filteredCards;        
        const finalFilteredCards = [
          ...topTwoFilteredPremiumCards, 
          ...(remainingFilteredCards?.length > 0 ? remainingFilteredCards : [])
        ];
        return {
          ...state,
          filteredCards: finalFilteredCards,
        };
      case TOGGLE_FAVORITE:
        return {
          ...state,
          cards: state.cards.map((card) =>
            card.id === action.payload ? { ...card, isFavorite: !card.isFavorite } : card
          ),
          filteredCards: state.filteredCards.map((card) =>
          card.id === action.payload ? { ...card, isFavorite: !card.isFavorite } : card
        ),
        };
      case SHOW_CATALOG: 
        return {
          ...state
        }
      default:
      return state;
    }
  };
  const shuffleArray = (array) => {
    if(array){
      const shuffledArray = [...array];
      for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
      }
      return shuffledArray;
    }
  };
  export default catalogReducer;

