import React, { useState, useEffect } from 'react';
import classes from "./ImageCard.module.css";
import { media_api } from "../../store/thunks/urls";
import imagePlaceholder from '../../image/png/placeholder.png';

const ImageCard = ({ photos }) => {
    const [imagePath, setImagePath] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (photos && photos?.length > 0) {
            const imagePathPreview = photos.find(photo => photo.id !== undefined && photo.is_preview === true)?.file_path;
            var imagePath = photos.find(photo => photo.id !== undefined)?.file_path;
            if(imagePathPreview){
                imagePath = imagePathPreview;
            }
            if (imagePath) {
                const fullImagePath = `${media_api}${imagePath}`;
                const img = new Image();
                img.src = fullImagePath;
                img.onload = () => {
                    setImagePath(fullImagePath);
                    setIsLoading(false);
                };
                img.onerror = () => {
                    setIsLoading(false);
                };
            } else {
                
                setIsLoading(false);
            }
        } else {
            setIsLoading(true);
        }
    }, [photos]);



    return (
       <div className={classes.image_box}>
            {isLoading ? (
                <img className={classes.image} src={imagePlaceholder} alt="Apartment" />
            ) : (
                <img className={classes.image} src={imagePath} alt="Apartment" />
            )}
        </div>
    );
};

export default ImageCard;