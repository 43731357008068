export const FETCH_FLAT_REQUEST = 'FETCH_FLAT_REQUEST';
export const FETCH_FLAT_SUCCESS = 'FETCH_FLAT_SUCCESS';
export const FETCH_FLAT_FAILURE = 'FETCH_FLAT_FAILURE';
export const CLEAR_FLAT_STATE = 'CLEAR_FLAT_STATE';

export const fetchFlatRequest = () => ({
  type: FETCH_FLAT_REQUEST
});

export const fetchFlatSuccess = (flatData) => ({
  type: FETCH_FLAT_SUCCESS,
  payload: flatData
});

export const fetchFlatFailure = (error) => ({
  type: FETCH_FLAT_FAILURE,
  payload: error
});
export const clearFlatState = () => ({
  type: CLEAR_FLAT_STATE
});