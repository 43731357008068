import is_balcony from '../../image/png/icons/white/icons8-balcony-100.png'
import is_balcony_dark from '../../image/png/icons/black/icons8-balcony-100.png'
import is_wifi from '../../image/png/icons/white/icons8-wi-fi-100.png'
import is_wifi_dark from '../../image/png/icons/black/icons8-wi-fi-100.png'
import is_car_charge from '../../image/png/icons/white/icons8-electric-vehicle-100.png'
import is_car_charge_dark from '../../image/png/icons/black/icons8-electric-vehicle-100.png'
import is_pets from '../../image/png/icons/white/icons8-cat-footprint-100.png'
import is_pets_dark from '../../image/png/icons/black/icons8-cat-footprint-100.png'
import is_conditioner from '../../image/png/icons/white/icons8-air-conditioner-100.png'
import is_conditioner_dark from '../../image/png/icons/black/icons8-air-conditioner-100.png'
import is_restaurant from '../../image/png/icons/white/icons8-tableware-100.png'
import is_restaurant_dark from '../../image/png/icons/black/icons8-tableware-100.png'
import is_tv from '../../image/png/icons/white/icons8-tv-100.png'
import is_tv_dark from '../../image/png/icons/black/icons8-tv-100.png'
import is_kitchenette from '../../image/png/icons/white/icons8-kitchen-room-100.png'
import is_kitchenette_dark from '../../image/png/icons/black/icons8-kitchen-room-100.png'
import is_kitchen_utensils from '../../image/png/icons/white/icons8-dining-room-100.png'
import is_kitchen_utensils_dark from '../../image/png/icons/black/icons8-dining-room-100.png'
import is_pool from '../../image/png/icons/white/icons8-lap-pool-100.png'
import is_pool_dark from '../../image/png/icons/black/icons8-lap-pool-100.png'
import is_parking from '../../image/png/icons/white/icons8-car-100.png'
import is_parking_dark from '../../image/png/icons/black/icons8-car-100.png'
import is_bathroom from '../../image/png/icons/white/icons8-bathtub-100.png'
import is_bathroom_dark from '../../image/png/icons/black/icons8-bathtub-100.png'
import is_jacuzzi from '../../image/png/icons/white/icons8-jacuzzi-100.png'
import is_jacuzzi_dark from '../../image/png/icons/black/icons8-jacuzzi-100.png'
import is_family_apartment from '../../image/png/icons/white/icons8-family-100.png'
import is_family_apartment_dark from '../../image/png/icons/black/icons8-family-100.png'
import is_safe from '../../image/png/icons/white/icons8-safe-100.png'
import is_safe_dark from '../../image/png/icons/black/icons8-safe-100.png'
import is_hair_dryer from '../../image/png/icons/white/icons8-hair-dryer-100.png'
import is_hair_dryer_dark from '../../image/png/icons/black/icons8-hair-dryer-100.png'
import is_iron_for_clothes from '../../image/png/icons/white/icons8-iron-100.png'
import is_iron_for_clothes_dark from '../../image/png/icons/black/icons8-iron-100.png'
import is_kettle from '../../image/png/icons/white/icons8-coffee-maker-100.png'
import is_kettle_dark from '../../image/png/icons/black/icons8-coffee-maker-100.png'
import is_fridge from '../../image/png/icons/white/icons8-fridge-100.png'
import is_fridge_dark from '../../image/png/icons/black/icons8-fridge-100.png'
import is_microwave from '../../image/png/icons/white/icons8-microwave-100.png'
import is_microwave_dark from '../../image/png/icons/black/icons8-microwave-100.png'
import is_daily_cleaning from '../../image/png/icons/white/icons8-vacuuming-100.png'
import is_daily_cleaning_dark from '../../image/png/icons/black/icons8-vacuuming-100.png'
import is_gym from '../../image/png/icons/white/icons8-dumbbell-100.png'
import is_gym_dark from '../../image/png/icons/black/icons8-dumbbell-100.png'
import is_desktop from '../../image/png/icons/white/icons8-desk-100.png'
import is_desktop_dark from '../../image/png/icons/black/icons8-desk-100.png'
import is_near_subway from '../../image/png/icons/white/icons8-subway-100.png'
import is_near_subway_dark from '../../image/png/icons/black/icons8-subway-100.png'
import {useTelegram} from "../../hooks/useTelegram";

export function useIcons() {
    const {webApp} = useTelegram();
    const icons = [
        {
            id:0,
            name: 'is_near_subway',
            image: webApp.colorScheme === 'dark'? is_near_subway : is_near_subway_dark
        },
        {
            id:1,
            name: 'is_balcony',
            image: webApp.colorScheme === 'dark'? is_balcony : is_balcony_dark
        },
        {
            id:2,
            name: 'is_wifi',
            image: webApp.colorScheme === 'dark'? is_wifi : is_wifi_dark
        },
        {
            id:3,
            name: 'is_car_charge',
            image: webApp.colorScheme === 'dark'? is_car_charge : is_car_charge_dark
        },
        {
            id:4,
            name: 'is_pets',
            image: webApp.colorScheme === 'dark'? is_pets : is_pets_dark
        },
        {
            id:5,
            name: 'is_conditioner',
            image: webApp.colorScheme === 'dark'? is_conditioner : is_conditioner_dark
        },
        {
            id:6,
            name: 'is_restaurant',
            image: webApp.colorScheme === 'dark'? is_restaurant : is_restaurant_dark
        },
        {
            id:7,
            name: 'is_tv',
            image: webApp.colorScheme === 'dark'? is_tv : is_tv_dark
        },
        {
            id:8,
            name: 'is_kitchenette',
            image: webApp.colorScheme === 'dark'? is_kitchenette : is_kitchenette_dark
        },
        {
            id:9,
            name: 'is_kitchen_utensils',
            image: webApp.colorScheme === 'dark'? is_kitchen_utensils : is_kitchen_utensils_dark
        },
        {
            id:10,
            name: 'is_pool',
            image: webApp.colorScheme === 'dark'? is_pool : is_pool_dark
        },
        {
            id:11,
            name: 'is_parking',
            image: webApp.colorScheme === 'dark'? is_parking : is_parking_dark
        },
        {
            id:12,
            name: 'is_bathroom',
            image: webApp.colorScheme === 'dark'? is_bathroom : is_bathroom_dark
        },
        {
            id:13,
            name: 'is_jacuzzi',
            image: webApp.colorScheme === 'dark'? is_jacuzzi : is_jacuzzi_dark
        },
        {
            id:14,
            name: 'is_family_apartment',
            image: webApp.colorScheme === 'dark'? is_family_apartment : is_family_apartment_dark
        },
        {
            id:15,
            name: 'is_safe',
            image: webApp.colorScheme === 'dark'? is_safe : is_safe_dark
        },
        {
            id:16,
            name: 'is_hair_dryer',
            image: webApp.colorScheme === 'dark'? is_hair_dryer : is_hair_dryer_dark
        },
        {
            id:17,
            name: 'is_iron_for_clothes',
            image: webApp.colorScheme === 'dark'? is_iron_for_clothes : is_iron_for_clothes_dark
        },
        {
            id:18,
            name: 'is_kettle',
            image: webApp.colorScheme === 'dark'? is_kettle : is_kettle_dark
        },
        {
            id:19,
            name: 'is_fridge',
            image: webApp.colorScheme === 'dark'? is_fridge : is_fridge_dark
        },
        {
            id:20,
            name: 'is_microwave',
            image: webApp.colorScheme === 'dark'? is_microwave : is_microwave_dark
        },
        {
            id:21,
            name: 'is_daily_cleaning',
            image: webApp.colorScheme === 'dark'? is_daily_cleaning : is_daily_cleaning_dark
        },
        {
            id:22,
            name: 'is_gym',
            image: webApp.colorScheme === 'dark'? is_gym : is_gym_dark
        },
        {
            id:23,
            name: 'is_desktop',
            image: webApp.colorScheme === 'dark'? is_desktop : is_desktop_dark
        },
        
    ];
    return icons;
}