import React, { useState, useEffect, useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { fetchPhotos } from '../../store/thunks/photosThunks';
import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import classes from "./ImageSlider.module.css";
import imagePlaceholder from '../../image/png/placeholder.png'; // Заглушка для изображения

const ImageSlider = ({ photos }) => {
const dispatch = useDispatch();
const paths = useSelector(state => state.photos.paths);
const [anyPhotos, setAnyPhotos] = useState(true);
  useEffect(() => {
    if (photos){
      if(photos?.length > 0){
        console.log(photos);
        dispatch(fetchPhotos(photos)); 
      }
      else{
        setAnyPhotos(false);
      }
    }
    
    // Вызываем thunk для загрузки фотографий при монтировании компонента
  }, [dispatch, photos]);


  return (
    <div className={classes.swiper_block}>
      {anyPhotos ?
      (<Swiper
        spaceBetween={30}
        slidesPerView={1}
        loop={true}
        navigation={true}
        modules={[Navigation]}
        pagination={{ clickable: true }}
        className={classes.swiper_custom}
      >
        {paths?.map((path, index) => (
          <SwiperSlide key={index} className={classes.swiper_slide}>
            { path === 'Failed to fetch image' ? ( // Отображение заглушки во время загрузки
              <img src={imagePlaceholder} className={classes.image} alt="Loading..." />
            ) : (
              <img
                src={path}
                alt={`Photo ${index}`}
                className={classes.image}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      ) : 
      (
        <img className={classes.image} src={imagePlaceholder} alt="Apartment" />
      )}
    </div>
  );
};

export default ImageSlider;
