import './App.css';
import { useSelector, useDispatch } from 'react-redux';
import Catalog from "./components/catalog/Catalog";
import Filter from "./components/filter/Filter";
import Flat from "./components/flat/Flat";
import Header from "./components/header/Header";
import {useEffect, useRef} from "react";
import {selectFlat, initAppAndButtons} from './store/thunks/appThunks';
import 'swiper/css';

function App() {
    const dispatch = useDispatch();
    const selectedFlat = useSelector( state => state?.app?.selectFlat);

    const AppRef = useRef(null);

    useEffect(() => {
        dispatch(initAppAndButtons());
    }, []);

    return (
        <div className="App" ref={AppRef}>
            {selectedFlat === -1 && (
            <div>
                <Header />
                <Filter />
                <Catalog setSelectFlat={selectFlat} appRef={AppRef}/>
            </div>
            )}
            {selectedFlat !== -1 && <Flat id={selectedFlat}/>}
        </div>
    );
}

export default App;
