export const FETCH_CARDS_REQUEST = 'FETCH_CARDS_REQUEST';
export const FETCH_CARDS_SUCCESS = 'FETCH_CARDS_SUCCESS';
export const FETCH_CARDS_FAILURE = 'FETCH_CARDS_FAILURE';
export const MARK_FAVORITES = 'MARK_FAVORITES';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const APPLY_RENT_TYPE_FILTER = 'APPLY_RENT_TYPE_FILTER';
export const APPLY_ROOMS_FILTER = 'APPLY_ROOMS_FILTER';
export const APPLY_LOCATION_FILTER = 'APPLY_LOCATION_FILTER';
export const APPLY_PRICE_FILTER = 'APPLY_PRICE_FILTER';
export const TOGGLE_FAVORITE  = 'TOGGLE_FAVORITE';
export const SHOW_CATALOG = 'SHOW_CATALOG';

export const fetchCardsRequest = () => ({
    type: FETCH_CARDS_REQUEST,
  });
  
export const fetchCardsSuccess = (cards) => ({
  type: FETCH_CARDS_SUCCESS,
  payload: cards,
});

export const fetchCardsFailure = (error) => ({
  type: FETCH_CARDS_FAILURE,
  payload: error,
});

export const markFavorites = (favorites) => ({
  type: MARK_FAVORITES,
  payload: favorites,
});

export const applyFilters = (selectedRentType, selectedRoomsCount, selectedLocation, priceRange, selectedRentPeriodType, selectedConveniences) => ({
  type: APPLY_FILTERS,
  payload: {rentType: selectedRentType,
            roomsCount: selectedRoomsCount,
            locations: selectedLocation,
            priceRange: priceRange,
            rentPeriodType: selectedRentPeriodType,
            conveniences: selectedConveniences},
});
export const applyRentTypeFilter = (selectedRentType) => ({
  type: APPLY_RENT_TYPE_FILTER,
  payload: selectedRentType,
});
export const applyRoomsFilter = (selectedRoomsCount) => ({
  type: APPLY_ROOMS_FILTER,
  payload: selectedRoomsCount,
});
export const applyLocationFilter = (selectedLocation) => ({
  type: APPLY_LOCATION_FILTER,
  payload: selectedLocation,
});

export const applyPriceFilter = (priceRange) => ({
  type: APPLY_PRICE_FILTER,
  payload: priceRange,
});


export const toggleFavorite = (id) => ({
    type: TOGGLE_FAVORITE,
    payload: id,
});

export const showCatalog = () => ({
    type: SHOW_CATALOG
});