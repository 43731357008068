import axios from 'axios';
import { fetchCardsRequest, fetchCardsSuccess, fetchCardsFailure, markFavorites } from '../actions/catalogActions';
import {api_addr} from './urls';
import { useTelegram } from '../../hooks/useTelegram'; 


const shuffleArray = (array) => {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
};


export const fetchCatalogData = () => async (dispatch) => {
  const {webApp} = useTelegram();  
  const telegramId = webApp.initDataUnsafe?.user?.id.toString();

  const url = api_addr + `/apartments`;
  const urlFavorites = api_addr + `/apartments/likes/${telegramId}`;
  dispatch(fetchCardsRequest());

  try {

    const cardsResponse = await axios.get(url);
    const cardsData = cardsResponse.data;
    
    //Перемешиваем случайным образом
    const shuffledCards = shuffleArray(cardsData);

    //Упорядочиваем новый порядок
    const cardsWithOrderId = shuffledCards.map((card, index) => ({
      ...card,
      order_id: index + 1, // Нумерация с 1
    }));

    // Отправляем полученные квартиры в хранилище
    dispatch(fetchCardsSuccess(cardsWithOrderId));

    // Получаем список избранных квартир пользователя
    const favoritesResponse = await axios.get(urlFavorites);
    const favoritesData = favoritesResponse.data;
    
    // Помечаем избранные квартиры в хранилище
    dispatch(markFavorites(favoritesData));
  } catch (error) {
    // Обработка ошибок
    dispatch(fetchCardsFailure(error.message));
  }
};

export const fetchCatalogDataTest = () => async (dispatch) => {
  dispatch(fetchCardsRequest());

    const cardsData = 
      [
        {
          "id": 0,
          "location": {
            "id": 0,
            "name": "Dubai Marina"
          },
          "price": 5400,
          "apartment_type": 0,
          "total_area": 40,
          "rooms": 1,
          "photos": []
        },
        {
          "id": 1,
          "location": {
            "id": 1,
            "name": "JBR"
          },
          "price": 9800,
          "apartment_type": 0,
          "total_area": 40,
          "rooms": 2,
          "photos": []
        },
        {
          "id": 2,
          "location": {
            "id": 2,
            "name": "JLT"
          },
          "price": 1234,
          "apartment_type": 0,
          "total_area": 40,
          "rooms": 3,
          "photos": []
        },
        {
          "id": 3,
          "location": {
            "id": 3,
            "name": "Tecom"
          },
          "price": 1000,
          "apartment_type": 0,
          "total_area": 40,
          "rooms": 4,
          "photos": []
        },
        {
          "id": 4,
          "location": {
            "id": 4,
            "name": "Greens"
          },
          "price": 10000,
          "apartment_type": 0,
          "total_area": 40,
          "rooms": 5,
          "photos": []
        }
      ];
    
    // Отправляем полученные квартиры в хранилище
    dispatch(fetchCardsSuccess(cardsData));

    // Получаем список избранных квартир пользователя
    const favoritesData = [1, 3];
    
    // Помечаем избранные квартиры в хранилище
    dispatch(markFavorites(favoritesData));
};