import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCatalogData, fetchCatalogDataTest } from '../../store/thunks/catalogThunks';
import {showCatalog} from '../../store/actions/catalogActions';
import Card from '../card/Card';
import classes from "./Catalog.module.css";


const ScrollToTopButton = ({ visible, onClick }) => {
  return (
    <div className={`${classes.scrollToTopButton} ${visible ? classes.show : classes.hide}`} onClick={onClick}>
       <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        fill="none"
        stroke="var(--tg-theme-button-color)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M12 8v8M8 12l4-4 4 4"></path>
      </svg>
    </div>
  );
};


const Catalog = ({setSelectFlat, appRef}) => {
  const dispatch = useDispatch();
  const { loading, filteredCards: cards, error } = useSelector((state) => state.catalog);
  const favoriteButtonPressed = useSelector(state => state.header.favoriteButtonPressed);
  const endIndexFromStore = useSelector(state => state.app.endIndex);
  const scrollTopFromStore = useSelector(state => state.app.scrollTop);
  const cardsToShow = favoriteButtonPressed ? cards?.filter(card => card.isFavorite) : cards;
  
  const [visibleCards, setVisibleCards] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(12);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    dispatch(showCatalog());
    //Вызываем тестовую функцию
    //dispatch(fetchCatalogDataTest());
    if(!cards || cards === undefined || cards?.length === 0){
      dispatch(fetchCatalogData());
    }
    if(endIndexFromStore !== 12){
      dispatch(setEndIndex(endIndexFromStore));
    }
    setTimeout(() => {
      if (appRef?.current) {
        appRef?.current.scrollTo({ top: scrollTopFromStore });
      }
    }, 30);
  }, [dispatch]);

  const handleScroll = () => {
    if (
      appRef?.current &&
      appRef?.current?.scrollHeight - appRef?.current?.scrollTop <= appRef?.current?.clientHeight + 50
    ) {
      setEndIndex(prevIndex => prevIndex + 12);
    }
  };

  const scrollToTop = () => {
    appRef?.current?.scrollTo({ top: 0, behavior: 'smooth' }); 
    setTimeout(() => {
      setShowScrollButton(false);
      setEndIndex(12);
    }, 1000);
  };

  useEffect(() => {
    if(endIndex > 24 && !showScrollButton){
      setShowScrollButton(true);
    }
  }, [appRef?.current?.scrollHeight]);
  
  useEffect(() => {
    if (cardsToShow) {
      setVisibleCards(cardsToShow.slice(startIndex, endIndex));
    }
  }, [cardsToShow, startIndex, endIndex]);

  useEffect(() => {
    if (appRef.current) {
      appRef?.current?.addEventListener('scroll', handleScroll);
      return () => {
        appRef.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [appRef.current]);

  return (
    <div className={classes._}>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        visibleCards?.map((card) => <Card key={card.order_id} setSelectFlat={setSelectFlat} {...card} endIndex={endIndex} appRef={appRef}/>)
      )}
      <ScrollToTopButton visible={showScrollButton} onClick={scrollToTop} />
    </div>
  );
};

export default Catalog;