import { loadPhotos, loadPhotosSuccess, loadPhotosFailure } from '../actions/photosActions';
import { media_api } from './urls';
import axios from 'axios';

export const fetchPhotos = (photos) => async (dispatch, getState) => {
  dispatch(loadPhotos()); // Диспатчим action о начале загрузки

  if (!photos || photos.length === 0) {
    dispatch(loadPhotosSuccess([])); // Установка пустого массива фотографий, если их нет
    return;
  }

  try {
    const firstPhotoPreview = photos.find(photo => photo.id !== undefined && photo.is_preview === true);
    var firstPhoto = photos.find(photo => photo.id !== undefined);
    if(firstPhotoPreview){
      firstPhoto = firstPhotoPreview;
    }
    if (!firstPhoto) {
      dispatch(loadPhotosSuccess([])); // Установка пустого массива фотографий, если нет информации о фото
      return;
    }

    const imagePath = firstPhoto.file_path;
    const fullImagePath = `${media_api}${imagePath}`;
    const img = new Image();
    img.src = fullImagePath;
    img.onload = () => {
      const paths = photos.map(photo => `${media_api}${photo.file_path}`);
      dispatch(loadPhotosSuccess(paths)); // Диспатчим action об успешной загрузке с полученными путями к изображениям
    };
    img.onerror = () => {
      dispatch(loadPhotosSuccess([])); // Установка пустого массива фотографий в случае ошибки загрузки изображения
    };
  } catch (error) {
    dispatch(loadPhotosFailure(error.message)); // Диспатчим action в случае ошибки
  }
};