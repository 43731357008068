import axios from 'axios';
import {bot_url_with_param, media_api} from './urls';
import {text} from "../../translate";

async function getImageFileFromUrl(imageUrl) {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      return new File([blob], 'apart.jpg', { type: blob.type });
    } catch (error) {
      console.error('Error fetching image:', error);
      return null;
    }
  }

export const shareFlat = async (id, imageUrl, webAppPlatform, lang) => {
  const urlWithParams = `${bot_url_with_param}${id}`;
  console.log(urlWithParams);
  const shareText = `${text[lang].shareFlat.text} ${urlWithParams}`;
  const shareData = {
      title: text[lang].shareFlat.title,
      text: shareText,
      //url:`${media_api}${imageUrl}`
    };
  if (imageUrl) {
    const imgPath = `${media_api}${imageUrl}`;
    const file = await getImageFileFromUrl(imgPath);
    console.log(file);
    if(file && file !== null){
      shareData.files = [file];
    }
  }

  if (webAppPlatform === 'android' || webAppPlatform === 'ios') {
    // Используем navigator.share для поделиться
    if (navigator.share) {
        navigator.share(shareData)
            .then(() => console.log('Successful share'))
            .catch((error) => console.log(`Error sharing: ${error}. Data: ${shareData}`));
    } else {
        //alert('Извините, ваш браузер не поддерживает функцию "Поделиться".');
        window.open(`https://t.me/share/url?url=${urlWithParams}&text=${text[lang].shareFlat.text}`);
    }
  } else {
      // Копируем текст с ссылкой в буфер обмена
      window.open(`https://t.me/share/url?url=${urlWithParams}&text=${text[lang].shareFlat.text}`);
      const copyText = `${shareData.title}\n${shareData.text}`;
      /*navigator.clipboard.writeText(copyText)
          .then(() => alert('Ссылка скопирована в буфер обмена'))
          .catch((error) => console.log('Error copying text', error));
      */
          

  }
};