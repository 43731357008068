import React from 'react';
import { createRoot } from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './store/store';
import { useCloudStorage } from './hooks/useCloudStorage';
import { useTelegram } from './hooks/useTelegram';

const Root = () => {
    const cloudStorageResult = useCloudStorage();
    const telegramResult = useTelegram();

    // Создаем store, передавая результаты хуков в middleware
    const reduxStore = configureStore(cloudStorageResult, telegramResult);

    return (
        <Provider store={reduxStore}>
            <App />
        </Provider>
    );
};

const root = createRoot(document.getElementById('root'));
root.render(<Root />);
